.modelinfo .seasons-btn-model {
    display: flex !important ;
    flex-direction: column !important ;
    width: 25% !important ;
  }
  .modelinfo .seasons-btn-model button {
    margin-bottom: 10px !important;
    margin: 5px 0px !important ;
    background: #1a2138 !important ;
    border: none !important ;
    padding: 0px !important ;
    color: #ffffff !important ;
    padding: 3px 20px !important ;
    border: 2px solid #1a2138 !important ;
    transition: 0.5s ease !important ;
    width: 100% !important ;
    border-radius: 4px;
  }
  

  .modelinfo .seasons-btn-model button:hover {
    background: rgb(255, 106, 60) !important ;
    border: 2px solid #ff6a3c !important ;
    color: #ffffff   !important ;
    transition: 0.5s ease-out !important ;
  }

  .modelinfo ul.mainlist {
    padding: 0px !important ;
    width: 100% !important ;
  }  